.container {
    width: 50px;
    height: 60vh;
    display: flex;
    flex-direction: column;

    /*border: 1px solid gray;*/

    position: absolute;
    top: 0;
    left: 0;

    background-color: white;
    cursor: grab;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    border-radius: 8px;
}

.grabbing {
    cursor: grabbing;
    box-shadow: 8px 8px 8px 0 rgba(34, 60, 80, 0.2);
}

.picture {
    flex-grow: 1;
    background-size: 60vmin;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.letter {
    font-size: 10vmin;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
}
