.container {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 5vmin;
    width: 40vw;
    height: 40vw;
    max-height: 40vw;
    max-width: 40vw;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.4);
    margin: auto;
    grid-area: word;
}

.container-empty {
    width: 40vw;
    height: 40vw;
    max-height: 40vw;
    max-width: 40vw;
    padding: 5vmin;

    grid-area: word;
}

.imageContainer {
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.word {
    white-space: nowrap;
    font-size: 6vw;
}

.word:first-letter {
    font-weight: bold;
    font-size: larger;
}

@media (max-aspect-ratio: 3/4) {
    .container {
        padding: 2vw;
        width: 38vw;
        height: 38vw;
        max-height: 38vw;
        max-width: 38vw;
    }

    .container-empty {
        padding: 2vw;
        width: 30vw;
        height: 30vw;
        max-height: 30vw;
        max-width: 30vw;
    }
}


@keyframes backInDown {
    0% {
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.appear {
    animation-name: backInDown;
    animation-delay: 1s;
    animation-fill-mode: both;
    animation-duration: 1s;
}
