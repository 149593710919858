.container {
    font-size: 20vmin;
    font-weight: bold;
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.4);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vmin;
    height: 30vmin;
    margin: auto;

    grid-area: letter;
}

@media (max-aspect-ratio: 3/4) {
    .container {
        width: 40vw;
        height: 40vw;
        font-size: 30vw;
    }
}


@keyframes backInDown {
    0% {
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.appear {
    animation-name: backInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
}

.invisible {
    visibility: hidden;
}
