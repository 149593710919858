.keyboard {
    --button-size: min( 6vw, 7vh);
    --font-size: calc(0.8 * var(--button-size))
}

@media (max-aspect-ratio: 3/4) {

    .keyboard {
        --button-size: 6vh;
        --font-size: calc(0.8 * var(--button-size))
    }
}

.keyboard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-area: keyboard;
}

.keyboard::after {
    content: "";
    flex: auto;
}

.button {
    cursor: pointer;
    margin: 0.5vw;
    border-radius: 5px;
    font-size: var(--font-size);
    width: var(--button-size);
    height: var(--button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #b8e1fc 0%,#a9d2f3 10%,#90bae4 25%,#90bcea 37%,#90bff0 50%,#6ba8e5 51%,#a2daf5 83%,#bdf3fd 100%);
}

