@value mediaSm, mediaMd from '../../styles/media.css' ;
@value blackText from '../../styles/colors.css' ;

.nav {
    justify-content: space-around;
    align-items: stretch;
    display: flex;
}

.alphabet {
    width: 200px;
}

.paint {
    width: 200px;
}

.link {
    transition: all 0.3s;
    text-decoration: none;
    color: blackText;
}

.link:hover {
    transform: scale(1.05);
    /*box-shadow: 0px 0px 21px 16px rgba(255, 255, 255, 0.47);*/
}

@media mediaSm {
    .nav {
        flex-direction: column;
    }
}

@media mediaMd {
    .link {
        flex-basis: 50%;
    }
}

