html,body {
  overflow: hidden
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  background: #ffffff;
  box-sizing: border-box;
  color: #111;

  position: relative;
  height: auto;
  min-height: 100vh
}

h1 {
  color: darkblue;
}

