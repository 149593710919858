.container {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}
