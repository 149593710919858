.absolute-container {
    position: fixed;
    top: 55px;
    left: 0;
}

.container {
    position: relative;
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 55px);
    max-height: calc(100vh - 55px);
    overflow: hidden;
    user-select: none;
}
