@value blueBorderColor, blackText from '../../styles/colors.css' ;
@value mediaMd, mediaSm from '../../styles/media.css' ;


.nav {
    justify-content: space-around;
    align-items: stretch;
    display: flex;
    max-width: 800px;
    margin: 0 auto;
}


.link {
    transition: all 0.3s;
    text-decoration: none;
    color: blackText;
}

.link:hover {
    transform: scale(1.05);
}


.blueBox {
    border: 1px solid blueBorderColor;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 240px;
    height: 240px;
}

.image {
    width: 200px;
    display: block;
    flex-grow: 1;
    align-self: center;
}

@media mediaSm {
    .nav {
        flex-direction: column;
    }
}
