@value blueBorderColor, blackText from '../../styles/colors.css' ;

.container {
    width: 200px;
    box-sizing: border-box;
}

/*
.link {
    text-decoration: none;
}

.imageContainer {
    padding: 20px;
    background: white url("./assets/border.svg");
    background-repeat: no-repeat;
    border-radius: 4px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
}

.image {
    display: block;
    width: 160px;
    height: 160px;
    flex-grow: 1;
    object-fit: contain;
}

.title {
    font-size: 16px;
    text-decoration: none;
    background: #fcfcf5;
    width: 80%;
    margin: -5px auto 0;
    color: black;
    padding: 2px 10px;
    border-radius: 2px;
}
*/

.imageContainer {
    flex-grow: 1;
    margin-bottom: 20px;
}

.link {
    transition: all 0.3s;
    text-decoration: none;
    color: black;
    padding: 20px;
    border: 1px solid blueBorderColor;
    border-radius: 10px;
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.link:hover {
    transform: scale(1.05);
    /*box-shadow: 0px 0px 21px 16px rgba(255, 255, 255, 0.47);*/
}

.image {
    display: block;
    width: 200px;
    height: 200px;
    flex-grow: 1;
    object-fit: contain;
}

.title {
    font-size: 20px;
    text-decoration: none;
    color: blackText;
    margin: 0;
    padding: 0;
}
