@value mediaMd, mediaLg from './media.css' ;

.mainFrame {
    padding: 20px;
    flex-grow: 1;
    /*width: min(1200px, 100vw);*/
    max-width: 1200px;
    align-self: center;
}


@media mediaMd {
    .mainFrame {
        min-width: calc(100% - 40px);
    }
}

@media mediaLg {
    .mainFrame {
        min-width: 1200px;
    }
}