@value blueBorderColor, blackText from '../../styles/colors.css' ;

.absoluteContainer {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.gameWindowContainer {
    margin: 5px;
    width: calc(100vw - 10px);
    height: calc(100vh - 10px);
    border-radius: 20px;

    background-color: white;
    background-image: url("window-bg.svg");
    background-attachment: fixed;
    background-size: cover;

    animation-name: backInDown;
    animation-fill-mode: both;
    animation-duration: 1s;
}

@media (hover: none) {
    .gameWindowContainer {
        background-image: url("window-bg.jpg");
    }
}

.windowHeader {
    border-radius: 20px 20px 0 0;
    background: rgba(255,255,255,0.5);
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeButton {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    fill: #693c0b;
    cursor: pointer;
}

@keyframes backInDown {
    0% {
        transform: translateY(-1200px);
        opacity: 0.7;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
