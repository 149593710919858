.absoluteContainer {
    position: fixed;
    top: 55px;
    left: 0;
}

.mainContainer {
    padding: 20px;
    width: calc(100vw - 20px);
    height: calc(100vh - 55px);
    box-sizing: border-box;

    display: grid;
    grid-template-areas:
            "letter word"
            "keyboard word";
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4%;
    margin: 0;
}


@media (max-aspect-ratio: 3/4) {
    .mainContainer {
        grid-template-areas:
                "word"
                "letter"
                "keyboard";
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }
}

.breadcrumbsContainer {
    padding: 20px;
    flex-grow: 1;
}