@value blueBorderColor, blackText from '../../styles/colors.css' ;

.imageContainer {
    flex-grow: 1;
    margin-bottom: 20px;
}

.link {
    transition: all 0.3s;
    text-decoration: none;
    color: blackText;
    padding: 20px;
    border: 1px solid blueBorderColor;
    background: linear-gradient(#FFFFFF, #e9f4fe);
    border-radius: 10px;
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link:hover {
    transform: scale(1.05);
    /*box-shadow: 0px 0px 21px 16px rgba(255, 255, 255, 0.47);*/
}

