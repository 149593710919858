.mainContainer {
    box-sizing: border-box;
    width: calc(100vw - 60px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.canvasHolder {
    flex-grow: 2;
    padding: 20px;
    background: url('./assets/wood.jpg');
    box-shadow: inset #ffffff 0 5px 8px -1px, -5px -5px 5px -5px rgba(0, 0, 0, 0.6) inset;
}

.svg {
    position: relative;
    background: #fff;
    border-radius: 2px;
    padding: 20px;
    cursor: crosshair;

}

.svg svg {
    width: calc(100% - 40px);
    height: calc(100vh - 120px);
}


@media screen and (max-aspect-ratio: 1/1){
    .mainContainer {
        flex-direction: column;
        padding: 20px 0;
    }

    .svg svg {
        width: 100%;
        height: calc(100vh - 280px);
    }
}

@media screen and (max-width: 460px) {

    .mainContainer {
        height: unset;
    }

    .svg svg {
        width: 100%;
        height: unset;
    }
}
